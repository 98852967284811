import React, { useState, useContext } from "react";
import SessionContext from "./SessionContext";
import Login from "./Login";
import Route from "./Route";
import List from "./List";

const Management = () => {
  const [userSession, setUserSession] = useContext(SessionContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [managementPage, setManagementPage] = useState("list");

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      {errorMessage != "" ? (
        <div className="alert alert-info p-2">{errorMessage}</div>
      ) : null}
      {!userSession ? (
        <Login
          setUserSession={setUserSession}
          setErrorMessage={setErrorMessage}
        />
      ) : managementPage === "list" ? (
        <List
          userSession={userSession}
          setUserSession={setUserSession}
          setManagementPage={setManagementPage}
          setErrorMessage={setErrorMessage}
        />
      ) : (
        <Route
          userSession={userSession}
          setUserSession={setUserSession}
          setManagementPage={setManagementPage}
          setErrorMessage={setErrorMessage}
        />
      )}
    </div>
  );
};

export default Management;
