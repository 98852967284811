import { useEffect, useState } from "react";
import {
  delRedirect,
  editRedirect,
  getUsersById,
} from "../services/APIService";
import { ListGroup, Badge } from "react-bootstrap";

const List = ({
  userSession,
  setUserSession,
  setManagementPage,
  setErrorMessage,
}) => {
  const [reload, setReload] = useState(false);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(" loading...");

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUsersById(userSession.id, userSession.token);
      if (data.response_code === 200) {
        setUserData(data.response_desc);
        setErrorMessage("");
      } else if (data.response_code === 666) {
        setUserSession(false);
      } else {
        setErrorMessage("");
        setLoading(" nothing.");
      }
    };
    fetchData();
  }, [reload]);

  const editAlias = async (uid, id, url) => {
    const newUrl = prompt("Enter new link:", url);
    if (newUrl !== null && newUrl !== "") {
      const data = await editRedirect(uid, id, newUrl, userSession.token);
      if (data.response_code === 200) {
        setErrorMessage(data.response_desc);
        setReload(!reload);
      } else if (data.response_code === 666) {
        setUserSesion(false);
      } else {
        setErrorMessage(data.response_desc);
      }
    }
  };

  const removeAlias = async (uid, rid) => {
    const data = await delRedirect(uid, rid, userSession.token);
    if (data.response_code === 200) {
      setErrorMessage(data.response_desc);
      setReload(!reload);
    } else if (data.response_code === 666) {
      setUserSesion(false);
    } else {
      setErrorMessage(data.response_desc);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center hycli-action-container">
      ur routes.
      {userData.length > 0
        ? userData.map((v, i) => (
            <ListGroup key={i}>
              <ListGroup.Item className="d-flex flex-column flex-md-row justify-content-md-between align-items-start align-items-md-center">
                <Badge bg="primary" pill>
                  {v[5]}
                </Badge>
                <div className="ms-md-2 me-auto">
                  {v[2]} -&gt; {v[3]}
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-secondary me-1"
                    onClick={() => editAlias(v[1], v[0], v[3])}
                  >
                    e
                  </button>
                  <button
                    className="btn btn-sm btn-secondary"
                    onClick={() =>
                      confirm(`Remove ${v[2]}?`) && removeAlias(v[1], v[0])
                    }
                  >
                    x
                  </button>
                </div>
              </ListGroup.Item>
            </ListGroup>
          ))
        : loading}
      <button
        className="btn btn-primary mt-2"
        onClick={() => setManagementPage("alias")}
      >
        add route.
      </button>
    </div>
  );
};

export default List;
