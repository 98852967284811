import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { getRedirectByAlias } from "../services/APIService";

const Redirect = () => {
  const [data, setData] = useState({});
  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      await getRedirectData();
    };
    fetchData();
  }, []);

  const getRedirectData = async () => {
    const d = await getRedirectByAlias(params.id);
    setData(d);
  };

  if (Object.keys(data).length > 0) {
    if (data.redirect === null) {
      return <Navigate to="/404" />;
    } else {
      window.location.assign(data.redirect);
    }
  }

  return <div className="text-center">checking...</div>;
};

export default Redirect;
