const getRedirectByAlias = async (alias) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_SERVER}/routes?rid=${alias}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((r) => {
      if (!r.ok) throw new Error("Something went wrong");
      return r.json();
    })
    .then((r) => {
      return r;
    })
    .catch((err) => {
      console.log(err);
    });

  return res;
};

const getUsersById = async (id, token) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_SERVER}/users?uid=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + token,
      },
    }
  )
    .then((r) => {
      if (!r.ok) throw new Error("Something went wrong");
      return r.json();
    })
    .then((r) => {
      return r;
    })
    .catch((err) => {
      console.log(err);
    });

  return res;
};

const addRedirect = async (user, alias, redirect, token) => {
  const res = await fetch(`${process.env.REACT_APP_API_SERVER}/route-add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({ user: user, alias: alias, redirect: redirect }),
  })
    .then((r) => {
      if (!r.ok) throw new Error("Something went wrong");
      return r.json();
    })
    .then((r) => {
      return r;
    })
    .catch((err) => {
      console.log(err);
    });

  return res;
};

const editRedirect = async (user, route, redirect, token) => {
  const res = await fetch(`${process.env.REACT_APP_API_SERVER}/route-edit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({ user: user, route: route, redirect: redirect }),
  })
    .then((r) => {
      if (!r.ok) throw new Error("Something went wrong");
      return r.json();
    })
    .then((r) => {
      return r;
    })
    .catch((err) => {
      console.log(err);
    });

  return res;
};

const delRedirect = async (user, route, token) => {
  const res = await fetch(`${process.env.REACT_APP_API_SERVER}/route-del`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({ user: user, route: route }),
  })
    .then((r) => {
      if (!r.ok) throw new Error("Something went wrong");
      return r.json();
    })
    .then((r) => {
      return r;
    })
    .catch((err) => {
      console.log(err);
    });

  return res;
};

export {
  getRedirectByAlias,
  getUsersById,
  addRedirect,
  editRedirect,
  delRedirect,
};
