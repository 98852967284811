const checkLogin = async (user, password) => {
  const res = await fetch(`${process.env.REACT_APP_API_SERVER}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user: user, password: password }),
  })
    .then((r) => {
      if (!r.ok) throw new Error("Something went wrong");
      return r.json();
    })
    .then((r) => {
      return r;
    })
    .catch((err) => {
      console.log(err);
    });

  return res;
};

const checkLogout = async (token) => {
  const res = await fetch(`${process.env.REACT_APP_API_SERVER}/logout`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  })
    .then((r) => {
      if (!r.ok) throw new Error("Something went wrong");
      return r.json();
    })
    .then((r) => {
      return r;
    })
    .catch((err) => {
      console.log(err);
    });

  return res;
};

export { checkLogin, checkLogout };
