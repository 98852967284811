import React, { useState } from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import NotFound from "./components/NotFound";
import Management from "./components/Management";
import Redirect from "./components/Redirect";
import SessionContext from "./components/SessionContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Stack, Row, Col } from "react-bootstrap";

const App = () => {
  const userSession = useState(false);

  return (
    <SessionContext.Provider value={userSession}>
      <BrowserRouter>
        <Header />
        <Stack className="col-10 col-sm-10 col-md-10 mx-auto p-3 mt-5">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <Routes>
                  <Route path="/:id" element={<Redirect />} />
                  <Route path="/404" element={<NotFound />} />
                  <Route path="/" element={<Management />} />
                </Routes>
              </Col>
            </Row>
          </Container>
        </Stack>
      </BrowserRouter>
    </SessionContext.Provider>
  );
};

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);
root.render(<App />);
