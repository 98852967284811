import React, { useContext } from "react";
import { Link } from "react-router-dom";
import SessionContext from "./SessionContext";
import { checkLogout } from "../services/UserService";

const Header = () => {
  const [userSession, setUserSession] = useContext(SessionContext);

  const userLogout = async () => {
    const data = await checkLogout(userSession.token);
    if (data.response_code === 200) {
      setUserSession(false);
    }
  };

  return (
    <div className="hycli-header container">
      <small></small>
      <Link to="/">
        <span className="muted">
          <b>h</b>ere<b>y</b>ou<b>c</b>an<b>.l</b>ink<b>i</b>t
        </span>
      </Link>
      {userSession ? (
        <small>
          <p className="d-inline-block me-1">
            <b>u:</b> {userSession.name}
          </p>
          <button onClick={userLogout}>∏</button>
        </small>
      ) : (
        <small></small>
      )}
    </div>
  );
};

export default Header;
