import { useRef, useState } from "react";
import { addRedirect } from "../services/APIService";

const Route = ({
  userSession,
  setUserSession,
  setManagementPage,
  setErrorMessage,
}) => {
  const [valid, setValid] = useState(false);
  const regex = new RegExp(
    /^(https:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/
  );
  const alias = useRef("");
  const redirect = useRef("");

  const checkInputs = () => {
    if (regex.test(redirect.current.value) && alias.current.value.length > 2) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const handleAdd = async () => {
    if (alias.current.value === "" || redirect.current.value === "") {
      setErrorMessage("no data.");
      checkInputs();
    } else {
      const data = await addRedirect(
        userSession.id,
        alias.current.value,
        redirect.current.value,
        userSession.token
      );
      if (data.response_code === 200) {
        setErrorMessage(`'${alias.current.value}' ` + data.response_desc);
        alias.current.value = "";
        redirect.current.value = "";
        checkInputs();
      } else if (data.response_code === 666) {
        setUserSession(false);
      } else {
        setErrorMessage(`'${alias.current.value}' ` + data.response_desc);
      }
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center hycli-action-container">
      add route.
      <div className="d-flex align-items-center">
        <input
          type="text"
          ref={alias}
          placeholder="alias"
          onBlur={checkInputs}
        />
        <span className="mx-2">-&gt;</span>
        <input
          type="url"
          ref={redirect}
          placeholder="link"
          onBlur={checkInputs}
          className="flex-grow-1"
        />
      </div>
      <div>
        <button
          className="btn btn-primary"
          onClick={handleAdd}
          disabled={valid ? false : true}
        >
          add.
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => {
            alias.current.value = "";
            redirect.current.value = "";
            checkInputs();
          }}
        >
          reset.
        </button>
      </div>
      <button
        className="btn btn-primary mt-2"
        onClick={() => setManagementPage("list")}
      >
        back to list.
      </button>
    </div>
  );
};

export default Route;
