import { useRef } from "react";
import { checkLogin } from "../services/UserService";

const Login = ({ setUserSession, setErrorMessage }) => {
  const user = useRef("");
  const pw = useRef("");

  const login = async () => {
    const data = await checkLogin(user.current.value, pw.current.value);
    if (data.response_code === 200) {
      setUserSession(data.response_desc);
      setErrorMessage("");
    } else {
      setErrorMessage(data.response_desc);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center hycli-action-container">
      login.
      <input type="text" placeholder="user" ref={user} />
      <input
        type="password"
        placeholder="password"
        ref={pw}
        onKeyDown={(e) => e.key === "Enter" && login()}
      />
    </div>
  );
};

export default Login;
